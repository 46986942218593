.orange {
  background-color: #f56300;
}

.light-orange {
  background-color: #fa9500;
}

.yellow {
  background-color: #ffc600;
}

.green {
  background-color: #8adb0d;
}

.dark-green {
  background-color: #1ad371;
}

.no-data {
  background-color: gray;
}

