.text-block {
  margin: 40px 0;
}

h3 {
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
}

