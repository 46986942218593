
.filter-faded {
  display: none;
}
.sub-text {
  text-transform: uppercase;
  color: #6A6F77;
  opacity: 0.8;
  font-size: 8px;
}
