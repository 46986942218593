.filter-faded {
  display: none;
}

.sub-text {
  text-transform: uppercase;
  color: #6a6f77;
  opacity: .8;
  font-size: 8px;
}

