.filter-faded {
  -moz-filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  filter: gray;
  filter: grayscale(100%);
}

.header {
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #061c3f;
  font-size: 13px;
  margin-bottom: 5px;
}

.tooltip-icon {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
}

