
.fa-list-ol {
  margin-right: 12px;
  color: #56bdc6;
}
.action-icons {
  text-align: right;
}
.link-with-icon {
  display: inline-block;
}
.action-icons .link-with-icon {
  margin-left: 10px;
}
.send-icon {
  background-image: url("./images/ahs-send.svg");
  background-size: 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  width: 20px;
  height: 20px;
  transition: background 0.2s;
  vertical-align: middle;
}
.send-icon:hover {
  filter: contrast(0%);
}

.survey-icon {
  background-image: url("./images/ahs-survey.svg");
  background-size: 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  width: 20px;
  height: 20px;
  transition: background 0.2s;
  vertical-align: middle;
  margin-right: 10px;
}
