
.filter-results-dropdown {
  width: 100%;
  height: 40px;
  display: flex;
  z-index: 100;
  justify-content: right;
  position: relative;
}

.dropdown-header {
  background-color: #fff;
  border: 0.75px solid #D4D4D4;
  height: 40px;
  text-transform: uppercase;
  color: black;
  display: flex;
  justify-content: left;
  align-items: left;
  padding-left: 20px;
}

.dropdown-header-icon {
  padding-right: 15px;
}

.dropdown-header-icon img {
  padding-bottom: 2px;
}

.dropdown-header-reset-icon {
  padding-left: 5px;
  cursor: pointer;
  display: inline;
}

.dropdown-header-reset-icon img {
  padding-bottom: 2px;
}

.dropdown-header-selected-department-block {
  padding-left: 15px;
  text-transform: lowercase;
  color: #73BBC4;
  font-weight: bold;
}

.dropdown-body {
  background-color: #F5F5F5;
  padding: 7px;
  position: absolute;
  top: 40px;
  overflow: scroll;
  min-height: 400px;
  max-height: 400px;
  min-width: 534px;
  right: 15px;
}

.action-buttons {
  border-top: 0px;
  background-color: #F5F5F5;
  box-shadow: 0px -2px 10px 0px #1C324B0D;
  padding: 7px 14px 7px 7px;
  position: absolute;
  top: 410px;
  min-width: 534px;
  right: 15px;
  text-align: right;
}

.action-buttons .apply-button {
  width: 128px;
  margin-left: 10px;
}

.action-buttons .cancel-button {
  width: 125px;
}

.dropdown-departments-header,
.dropdown-analysis-groups-header {
  font-weight: bold;
  padding: inherit;
  color: #73BBC4;
}

.dropdown-departments,
.dropdown-analysis-groups,
.dropdown-whole-company {
  padding: inherit;
  display: inherit !important;
}

.custom-checkbox-box {
  float: right;
}

.custom-checkbox-label {
  float: left;
  margin-left:7px;
}

.dropdown-whole-company .custom-checkbox-label,
.dropdown-departments .custom-checkbox-label,
.dropdown-analysis-groups .custom-checkbox-label {
  font-weight: 600;
}

.custom-checkbox-label::v-deep.checked {
  color: #0B1D3D;
}

.custom-checkbox .custom-checkbox-label.group-department-label {
  font-weight: normal;
  float: none;
}

.group-departments .custom-checkbox {
  margin: 5px 0 5px 0;
}
.group-departments .dropdown-analysis-groups {
  margin-bottom:0;
}
