
.filter-faded {
  -moz-filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  filter: gray; /* IE6-9 */
  filter: grayscale(100%);
}

.demo-data {
  font-size: 14px;
  text-transform: uppercase;
  color: #dedede;
  text-align: center;
}

