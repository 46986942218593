.form-group label.disabled {
  text-decoration: line-through;
}

.form-group input {
  appearance: checkbox;
  -webkit-appearance: checkbox;
  -moz-appearance: checkbox;
  -ms-progress-appearance: checkbox;
}

.form-group {
  margin-bottom: 5px;
}

.question {
  font-weight: bold;
  font-size: 1.1rem;
}

.department-select {
  max-height: 200px;
  overflow-y: auto;
}

