
.orange {
  background-color: #F56300;
}
.light-orange {
  background-color: #FA9500;
}
.yellow {
  background-color: #FFC600;
}
.green {
  background-color: #8ADB0D;
}
.dark-green {
  background-color: #1AD371;
}
.no-data {
  background-color: #808080;
}
