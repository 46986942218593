
.small-header {
  font-size: 18px;
  text-transform: none;
}

.text-block {
  color: #6A6C6E;
  font-size: 13px;
  margin-bottom: 20px;
}

.text-block.border-top {
  padding-top: 30px;
  border-top: 1px solid #D4D4D4;
}

.text-block.border-bottom {
  padding-bottom: 30px;
  border-bottom: 1px solid #D4D4D4;
}

.summary-overview-image {
  width: 120px;
  height: 120px;
  border: 4px solid green;
  border-radius: 95px;
  margin-bottom: 10px;
  position: relative;
}

.summary-overview-image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.summary-overview-image-badge {
  position: absolute;
  top: 0;
  right: 0;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.5);
}

.summary-overview-image-badge .fa {
  color: white;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.top-text .heading {
  margin-top: 40px;
  font-size: 20px;
  font-weight:bold;
}

.top-text .sub-text {
  font-size: 13px;
  color: #ACAFB3;
}
