
.title-input {
  width: 100%;
}
.title-input.disabled {
  margin-left: 50px;
}
.options-inputs {
  margin-right: 50px;
}
