.top-text,
.bottom-text {
  font-family: "Montserrat";
  display: block;
  margin: 0 auto;
}

.row-text {
  display: flex;
  color: #6a6c6e;
  font-size: 14px;
  text-align: left;
  align-items: center;
}

.compass-image {
  width: 73px;
  height: 73px;
  margin: 35px auto 15px;
}

.top-text,
.headings {
  display: block;
  margin: 0 auto;
}

.top-text .heading {
  margin: auto;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.top-text .sub-heading {
  font-size: 14px;
  color: #6a6c6e;
  text-align: center;
  font-style: italic;
}

.row-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.row-image img {
  height: 40px;
}

.list-row {
  margin: 30px 0;
}

