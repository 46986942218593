.indicator {
  margin-left: 20px;
  margin-top: 4px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #8adb0d;
}

.indicator.grey {
  background-color: #aaa;
}

.close-icon {
  margin-right: 10px;
}

.group-overflow div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
}

