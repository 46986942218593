
.test-survey-guide-container {
  width: 100%;
  padding: 5px;
  p {
    font-size: 11px;
    line-height: 1.5;
    margin-bottom: 0;
  }

  .test-survey-guide-stage-1 {
    justify-content: right;
  }

  .submit-button {
    display: block;
    font-size: 14px;
    font-weight: bold;
    padding: 15px 0 16px;
    text-transform: none;
  }

  .guide-title {
    font-weight: bold;
    line-height: 1.6;
  }

  .guide-text-stage-1 {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    font-size: 11px;
    line-height: 1.6;
  }

  .guide-text-stage-2 {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    font-size: 11px;
    line-height: 1.4;
  }

  .button-container {
    height: 100%;
    align-self: center;
  }

  .underlined-link {
    line-height: 2;
    text-decoration: underline;
    color: #56bdc6;
    font-weight: bold;
  }
}

