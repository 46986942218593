


























































































































































































































































.vdatetime-fade-enter-active,
.vdatetime-fade-leave-active {
  transition: opacity .4s;
}

.vdatetime-fade-enter,
.vdatetime-fade-leave-to {
  opacity: 0;
}

.vdatetime-overlay {
  z-index: 999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, .5);
  transition: opacity .5s;
}























































































































































































































































































.vdatetime-popup {
  box-sizing: border-box;
  z-index: 1000;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 340px;
  max-width: calc(100% - 30px);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .3);
  color: #444;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.18;
  background: #fff;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.vdatetime-popup * {
    box-sizing: border-box
}

.vdatetime-popup__header {
  padding: 18px 30px;
  background: #3f51b5;
  color: #fff;
  font-size: 32px;
}

.vdatetime-popup__title {
  margin-bottom: 8px;
  font-size: 21px;
  font-weight: 300;
}

.vdatetime-popup__year {
  font-weight: 300;
  font-size: 14px;
  opacity: 0.7;
  cursor: pointer;
  transition: opacity .3s
}

.vdatetime-popup__year:hover {
    opacity: 1
}

.vdatetime-popup__date {
  line-height: 1;
  cursor: pointer;
}

.vdatetime-popup__actions {
  padding: 0 20px 10px 30px;
  text-align: right;
}

.vdatetime-popup__actions__button {
  display: inline-block;
  border: none;
  padding: 10px 20px;
  background: transparent;
  font-size: 16px;
  color: #3f51b5;
  cursor: pointer;
  transition: color .3s
}

.vdatetime-popup__actions__button:hover {
    color: #444
}
.vdatetime-calendar__navigation--previous:hover svg path, .vdatetime-calendar__navigation--next:hover svg path {
    stroke: #888;
}








































































































.vdatetime-calendar__navigation,
.vdatetime-calendar__navigation * {
  box-sizing: border-box;
}

.vdatetime-calendar__navigation {
  position: relative;
  margin: 15px 0;
  padding: 0 30px;
  width: 100%;
}

.vdatetime-calendar__navigation--previous,
.vdatetime-calendar__navigation--next {
  position: absolute;
  top: 0;
  padding: 0 5px;
  width: 18px;
  cursor: pointer
}

.vdatetime-calendar__navigation--previous svg, .vdatetime-calendar__navigation--next svg {
    width: 8px;
}

.vdatetime-calendar__navigation--previous svg path, .vdatetime-calendar__navigation--next svg path {
      transition: stroke .3s;
}

.vdatetime-calendar__navigation--previous {
  left: 25px;
}

.vdatetime-calendar__navigation--next {
  right: 25px;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

.vdatetime-calendar__current--month {
  text-align: center;
  text-transform: capitalize;
}

.vdatetime-calendar__month {
  padding: 0 20px;
  transition: height .2s;
}

.vdatetime-calendar__month__weekday,
.vdatetime-calendar__month__day {
  display: inline-block;
  width: 14.28571%;
  line-height: 36px;
  text-align: center;
  font-size: 15px;
  font-weight: 300;
  cursor: pointer
}

.vdatetime-calendar__month__weekday > span, .vdatetime-calendar__month__day > span {
    display: block;
    width: 100%;
    position: relative;
    height: 0;
    padding: 0 0 100%;
    overflow: hidden;
}

.vdatetime-calendar__month__weekday > span > span, .vdatetime-calendar__month__day > span > span {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 0;
      border-radius: 50%;
      transition: background-color .3s, color .3s;
}

.vdatetime-calendar__month__weekday {
  font-weight: bold;
}

.vdatetime-calendar__month__day:hover > span > span {
  background: #eee;
}

.vdatetime-calendar__month__day--selected {
}

.vdatetime-calendar__month__day--selected > span > span,
  .vdatetime-calendar__month__day--selected:hover > span > span {
    color: #fff;
    background: #3f51b5;
}

.vdatetime-calendar__month__day--disabled {
  opacity: 0.4;
  cursor: default
}

.vdatetime-calendar__month__day--disabled:hover > span > span {
    color: inherit;
    background: transparent;
}
.vdatetime-time-picker__list::-webkit-scrollbar-thumb {
    background: #ccc
}












































































































































.vdatetime-time-picker__list::-webkit-scrollbar-track {
    background: #efefef
}












































































































































.vdatetime-time-picker * {
    box-sizing: border-box
}












































































































































.vdatetime-time-picker {
  box-sizing: border-box
}












































































































































.vdatetime-time-picker::after {
    content: '';
    display: table;
    clear: both
}

.vdatetime-time-picker__list {
  float: left;
  width: 50%;
  height: 305px;
  overflow-y: scroll
}

.vdatetime-time-picker__list::-webkit-scrollbar {
    width: 3px
}

.vdatetime-time-picker__with-suffix .vdatetime-time-picker__list {
  width: 33.3%;
}

.vdatetime-time-picker__item {
  padding: 10px 0;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  transition: font-size .3s;
}

.vdatetime-time-picker__item:hover {
  font-size: 32px;
}

.vdatetime-time-picker__item--selected {
  color: #3f51b5;
  font-size: 32px;
}

.vdatetime-time-picker__item--disabled {
  opacity: 0.4;
  cursor: default;
  font-size: 20px !important;
}
.vdatetime-year-picker__list::-webkit-scrollbar-thumb {
    background: #ccc
}
































































.vdatetime-year-picker__list::-webkit-scrollbar-track {
    background: #efefef
}
































































.vdatetime-year-picker * {
    box-sizing: border-box
}
































































.vdatetime-year-picker {
  box-sizing: border-box
}
































































.vdatetime-year-picker::after {
    content: '';
    display: table;
    clear: both
}

.vdatetime-year-picker__list {
  float: left;
  width: 100%;
  height: 305px;
  overflow-y: scroll
}

.vdatetime-year-picker__list::-webkit-scrollbar {
    width: 3px
}

.vdatetime-year-picker__item {
  padding: 10px 0;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  transition: font-size .3s;
}

.vdatetime-year-picker__item:hover {
  font-size: 32px;
}

.vdatetime-year-picker__item--selected {
  color: #3f51b5;
  font-size: 32px;
}

.vdatetime-year-picker__item--disabled {
  opacity: 0.4;
  cursor: default
}

.vdatetime-year-picker__item--disabled:hover {
    color: inherit;
    background: transparent
}
.vdatetime-month-picker__list::-webkit-scrollbar-thumb {
    background: #ccc
}





































































.vdatetime-month-picker__list::-webkit-scrollbar-track {
    background: #efefef
}





































































.vdatetime-month-picker * {
    box-sizing: border-box
}





































































.vdatetime-month-picker {
  box-sizing: border-box
}





































































.vdatetime-month-picker::after {
    content: '';
    display: table;
    clear: both
}

.vdatetime-month-picker__list {
  float: left;
  width: 100%;
  height: 305px;
  overflow-y: scroll
}

.vdatetime-month-picker__list::-webkit-scrollbar {
    width: 3px
}

.vdatetime-month-picker__item {
  padding: 10px 0;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  transition: font-size .3s;
}

.vdatetime-month-picker__item:hover {
  font-size: 32px;
}

.vdatetime-month-picker__item--selected {
  color: #3f51b5;
  font-size: 32px;
}

.vdatetime-month-picker__item--disabled {
  opacity: 0.4;
  cursor: default
}

.vdatetime-month-picker__item--disabled:hover {
    color: inherit;
    background: transparent
}
