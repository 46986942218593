
.how-it-works-container {
  border-style: solid;
  border-color: #55bec5;
  border-width: medium;
  border-radius: 10px;
  margin-bottom: 10px;
}

.how-it-works-header {
  background-color: #55bec5;
  height: auto;
  color: white;
  text-align: center;
  align-content: center;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  position: static;
  height: 40px;
  .toggle-how-it-works {
    font-size: 16px;
    float: right;
    padding-right: 0.5rem;
    position: relative;
    top: -2px;
  }
}

.how-it-works-body {
  justify-content: center;
  padding: 3rem 1rem 1rem;
}

.invisible {
  display: none;
}

.image {
  height: 80px;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  img {
  align-items: baseline;
  height: 70px;
  }
}

.text p {
  display: flex;
  align-items: center;
  text-align: center;
  color: #9e9e9e;
  font-size: 13px;
  font-weight: 500;
}

.arrow {
  padding: 1rem 1.5rem;
}
